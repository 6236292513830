import React, { Component } from "react";

class Equipe extends Component {
  state = {};
  render() {
    if(!this.props.equipePage){
      return null
    }
    return (
      <div id="equipe" className="section__pressbook_agenda_equipe">
        <div className="container">
          <div className="row">
            <div className="col-12 section__equipe">
              <h2
                className="section__title"
                dangerouslySetInnerHTML={{
                  __html: this.props.equipePage.title.rendered
                }}
              />
              <div
                className="section__equipe_content"
                dangerouslySetInnerHTML={{
                  __html: this.props.equipePage.content.rendered
                }}
              />
              {/* <a href={`creditos?lang=${this.props.lang}`}>
                <button className="btn btn-danger mt-3">
                  {this.props.lang === "en"
                    ? "Full credits here"
                    : "Veja os créditos completos"}
                </button>
              </a> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Equipe;
