import React, { Component } from "react";

class PressBook extends Component {
  state = {};
  render() {
    return (
      <div id="pressbook" className="section__pressbook">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="section__title">Press Book</h2>
              <button className="btn btn-light">
                Fazer o download do Press Book
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PressBook;
