import React, { Component } from "react";

class LoadingScreen extends Component {
  state = {};
  render() {
    return (
      <div className="loadingScreen">
        <p>{this.state.loadingMessage}</p>
      </div>
    );
  }
}

export default LoadingScreen;
