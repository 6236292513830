import React, { Component } from "react";
import videoBg from "../assets/bgedit.mp4";
import netflixLogo from "../assets/Netflix_Logo_RGB.png";
import laurels from "../assets/laurels.png";

class Hero extends Component {
  state = {
    inMobile: true
  };
  componentDidMount() {
    if (window.innerWidth > 767) {
      this.setState({ inMobile: false });
    }
  }
  render() {
    if(!this.props.heroPage){
      return null
    }
    return (
      <React.Fragment>
        <div className="heroWrapper">
          <div className={this.state.inMobile ? "hero withBg" : "hero"}>
            <div className="hero__title">
              <div
                className="hero__title-content"
                dangerouslySetInnerHTML={{
                  __html: this.props.heroPage.content.rendered
                }}
              />
              <img
                className="laurel"
                src={
                  laurels
                }
                alt=""
              />
            </div>
            <img src={netflixLogo} alt="" className="netflixLogo mb-3" />
            {this.state.inMobile ? (
              ""
            ) : (
              <video muted loop id="videoBg">
                <source src={videoBg} type="video/mp4" />
              </video>
            )}

            {/* <img
          src="https://picsum.photos/1600/900"
          alt=""
          className={this.state.inMobile ? "bgFallback" : "d-none"}
        /> */}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Hero;
