import React, { Component } from "react";

class Agenda extends Component {
  state = {};
  render() {
    if(!this.props.agendaPage){
      return null
    }
    return (
      <div id="agenda" className="section__agenda">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                className="section__title"
                dangerouslySetInnerHTML={{
                  __html: this.props.agendaPage.title.rendered
                }}
              />
            </div>
            <div
              className="content col-12"
              dangerouslySetInnerHTML={{
                __html: this.props.agendaPage.content.rendered
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default Agenda;
