import React from "react";

const Assistir = props => {
  return (
    <a
      href="https://www.netflix.com/watch/80190535"
      target="_blank"
      rel="noopener noreferrer"
    >
      <div className="assistir_filme">
        <p>
          {props.lang === "pt-br" ? "Assistir ao filme" : "Watch the movie"}
        </p>
      </div>
    </a>
  );
};

export default Assistir;
