import React from "react";
import App from "./App";
import $ from "jquery";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Creditos from "./components/creditos";
import Post from "./components/post";

window.jQuery = $;
window.$ = $;
global.jQuery = $;

const AppRouter = () => {
  return (
    <Router>
      <div>
        <Route path="/" exact component={App} />
        <Route path="/mediacoverage/:slug/:id" component={Post} />
        <Route path="/creditos" component={Creditos} />
      </div>
    </Router>
  );
};

export default AppRouter;
