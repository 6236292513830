import React, { Component } from "react";

class Contato extends Component {
  state = {};
  render() {
    if(!this.props.contatoPage){
      return null
    }
    return (
      <div id="contato" className="section__contato">
        <div className="container">
          <div className="row h-100">
            <div className="col-12 text-center align-self-center">
              <h2
                className="section__title"
                dangerouslySetInnerHTML={{
                  __html: this.props.contatoPage.title.rendered
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: this.props.contatoPage.content.rendered
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contato;
