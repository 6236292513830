import React, { Component } from "react";
import { Waypoint } from "react-waypoint";

class Stills extends Component {
  state = {
    load: false
  };
  _handleEnter = () => {
    this.setState({ load: true });
  };
  render() {
    if(!this.props.stillsPage){
      return null
    }
    return (
      <div id="stills" className="section__stills">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                className="section__title red"
                dangerouslySetInnerHTML={{
                  __html: this.props.stillsPage.title.rendered
                }}
              />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            {this.props.stillsPage.acf.galeria.map(imagem => {
              if(!imagem.sizes){
                return;
              }
              return (
                <div
                  className="col-6 col-lg-3 still-frame"
                  key={imagem.id + "imagem"}
                >
                  <img
                    rel={"still" + imagem.title}
                    alt={"still" + imagem.title}
                    src={imagem.sizes.medium}
                    onClick={() => this.props.onOpenStill(imagem.ID)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default Stills;
