import React, { Component } from "react";
import { Waypoint } from "react-waypoint";

class Sinopse extends Component {
  state = {
    isVisible: true
  };
  _handleEnter = () => {
    this.setState({ isVisible: true });
  };
  _handleLeave = () => {
    this.setState({ isVisible: false });
  };
  render() {
    if(!this.props.sinopsePage || !this.props.sinopsePage._embedded["wp:featuredmedia"]){
      return null
    }
    const posterCol = (
      <div className="col-md-4 align-self-center">
        <img
          className={this.state.isVisible ? "show" : ""}
          src={
            this.props.sinopsePage._embedded["wp:featuredmedia"][0]
              .media_details.sizes.full.source_url
          }
          alt=""
        />
      </div>
    );
    return (
      <div id="sinopse" className="section__sinopse_trailer">
        <div className="section__sinopse">
          <div className="container">
            <div
              className={
                this.props.sinopsePage.acf.exibir_poster
                  ? "row"
                  : "row justify-content-center"
              }
            >
              <div
                className={
                  this.props.sinopsePage.acf.exibir_poster
                    ? "col-md-8 align-self-center"
                    : "col-md-8 align-self-center text-center"
                }
              >
                <h2
                  className={
                    this.state.isVisible
                      ? "section__title show"
                      : "section__title"
                  }
                  dangerouslySetInnerHTML={{
                    __html: this.props.sinopsePage.title.rendered
                  }}
                />
                <div
                  className={
                    this.state.isVisible
                      ? "section__sinopse_content show"
                      : "section__sinopse_content"
                  }
                  dangerouslySetInnerHTML={{
                    __html: this.props.sinopsePage.content.rendered
                  }}
                />
              </div>
              {this.props.sinopsePage.acf.exibir_poster ? posterCol : ""}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Sinopse;
