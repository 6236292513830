import React, { Component } from "react";
import axios from "axios";

const api = "http://68.183.21.66/?rest_route=/wp/v2";

class Creditos extends Component {
  page = { "pt-br": "219", en: "217" };
  state = {
    creditsPage: { content: {}, title: {} },
    lang: "pt-br"
  };
  getContent = async lang => {
    const getCreditsPage = axios.get(api + "/pages/" + this.page[lang]);
    let creditsPage = await getCreditsPage;
    creditsPage = creditsPage.data;
    this.setState({ creditsPage });
  };
  componentDidMount() {
    const query = this.props.location.search;
    const params = new URLSearchParams(query);
    const lang = params.get("lang");
    if (lang === "en") {
      this.setState({ lang }, () => {
        this.getContent(this.state.lang);
      });
    } else {
      this.getContent(this.state.lang);
    }
  }
  render() {
    if(!this.props.creditsPage){
      return null
    }
    return (
      <div className="page_credits pb-5">
        <div className="container">
          <div className="row">
            <div className="col-12 mt-3">
              <a href={`/?lang=${this.state.lang}`}>
                {this.state.lang === "en" ? "Go Back" : "Voltar"}
              </a>
              <h1
                className="section__title mt-3"
                dangerouslySetInnerHTML={{
                  __html: this.state.creditsPage.title.rendered
                }}
              />
              <div
                dangerouslySetInnerHTML={{
                  __html: this.state.creditsPage.content.rendered
                }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Creditos;
