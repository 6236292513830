import React, { Component } from "react";

class StillOverlay extends Component {
  state = {};
  render() {
    if(!this.props.stillsPage){
      return null
    }
    const imagens = [...this.props.stillsPage.acf.galeria];
    const imagem = imagens.filter(imagem => {
      return imagem.ID !== this.props.selectedStill.ID;
    });
    let index = imagens
      .map(function(e) {
        return e.ID;
      })
      .indexOf(this.props.selectedStill.ID);
    let previous = index === 0 ? imagens[15] : imagens[index - 1];

    let next = index === 15 ? imagens[0] : imagens[index + 1];
    console.log(index);

    return (
      <div
        className={this.props.openStill ? "stillOverlay show" : "stillOverlay"}
      >
        <div className="closeButton" onClick={() => this.props.onCloseStill()}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
            <path d="M323.1 441l53.9-53.9c9.4-9.4 9.4-24.5 0-33.9L279.8 256l97.2-97.2c9.4-9.4 9.4-24.5 0-33.9L323.1 71c-9.4-9.4-24.5-9.4-33.9 0L192 168.2 94.8 71c-9.4-9.4-24.5-9.4-33.9 0L7 124.9c-9.4 9.4-9.4 24.5 0 33.9l97.2 97.2L7 353.2c-9.4 9.4-9.4 24.5 0 33.9L60.9 441c9.4 9.4 24.5 9.4 33.9 0l97.2-97.2 97.2 97.2c9.3 9.3 24.5 9.3 33.9 0z" />
          </svg>
        </div>
        <div
          className="stillShadow"
          onClick={() => this.props.onCloseStill()}
        />
        <div className="stillFrame">
          <img src={this.props.selectedStill.url} alt="" />
        </div>
        {next !== undefined && next.sizes ? (
          <img
            src={next.sizes.medium}
            alt=""
            className="nextStill"
            onClick={() => this.props.onOpenStill(next.ID)}
          />
        ) : (
          ""
        )}
        {previous !== undefined && previous.sizes ? (
          <img
            src={previous.sizes.medium}
            alt=""
            className="previousStill"
            onClick={() => this.props.onOpenStill(previous.ID)}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default StillOverlay;
