import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fab, fas);

const api = "//democraciaemvertigem.com/wp-json/wp/v2";

class Post extends Component {
  state = {
    post: { title: { rendered: "" }, content: { rendered: "" }, acf: {} }
  };
  getPost = async () => {
    const id = this.props.match.params.id;
    const postPromise = await axios.get(`${api}/mediacoverage/${id}?embed`);
    const post = await postPromise.data;
    this.setState({ post });
  };
  componentDidMount() {
    this.getPost();
  }
  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      window.scrollTo(0, 0);
    }
  }
  _handleSocialPopup = e => {
    e.preventDefault();
    const url = e.currentTarget.getAttribute("href");
    window.open(
      url,
      "socialShareWindow",
      "height=600, width=800, top=" +
        (window.clientHeight / 2 - 275) +
        ", left=" +
        (window.clientHeight / 2 - 225) +
        ", toolbar=0, location=0, menubar=0, directories=0, scrollbars=0"
    );
    return false;
  };
  render() {
    const { post } = this.state;
    var url_string = window.location.href;
    var url = new URL(url_string);
    var lang = url.searchParams.get("lang");
    console.log(lang);
    return (
      <div className="post">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-12 ">
              <Link to={`/?lang=${lang}`}>
                <FontAwesomeIcon icon={["fas", "home"]} />
              </Link>
            </div>
            <div className="col-12 col-sm-auto col-lg-auto">
              <div className="social-share">
                <ul>
                  <li>
                    <span>
                      <FontAwesomeIcon icon={["fas", "share"]} />
                    </span>
                  </li>
                  <li>
                    <a
                      onClick={this._handleSocialPopup}
                      className="popup-share facebook"
                      target="_blank"
                      href={`http://www.facebook.com/sharer.php?u=${post.link}`}
                    >
                      <FontAwesomeIcon icon={["fab", "facebook-f"]} />
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this._handleSocialPopup}
                      className="popup-share twitter"
                      target="_blank"
                      href={`http://twitter.com/intent/tweet?url=${
                        post.link
                      }&via=petracostal`}
                    >
                      <FontAwesomeIcon icon={["fab", "twitter"]} />
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this._handleSocialPopup}
                      className="popup-share pinterest"
                      target="_blank"
                      href={`http://pinterest.com/pin/create/button/?url=${
                        post.link
                      }&description=${post.title.rendered}`}
                    >
                      <FontAwesomeIcon icon={["fab", "pinterest-p"]} />
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this._handleSocialPopup}
                      className="popup-share linkedin"
                      target="_blank"
                      href={`http://linkedin.com/shareArticle?mini=true&url=${
                        post.link
                      }&title=${post.title.rendered}`}
                    >
                      <FontAwesomeIcon icon={["fab", "linkedin-in"]} />
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={this._handleSocialPopup}
                      href={`https://wa.me/?text=${post.link}`}
                      className="whatsapp"
                    >
                      <FontAwesomeIcon icon={["fab", "whatsapp"]} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-sm-9 col-md-10  col-lg-8  post_container">
              <span className="veiculo">{post.acf.veiculo}</span>
              <h1 dangerouslySetInnerHTML={{ __html: post.title.rendered }} />
              <div
                dangerouslySetInnerHTML={{ __html: post.content.rendered }}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Post;
