import React, { Component } from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import PT from "../assets/pt.png";
import EN from "../assets/en.png";

class Navbar extends Component {
  state = {
    expanded: false
  };
  _handleExpand = () => {
    const expanded = !this.state.expanded;
    this.setState({ expanded });
  };
  render() {
    const { items } = this.props;
    let title = "";
    this.props.lang === "pt-br"
      ? (title = "Democracia em Vertigem")
      : (title = "The Edge of Democracy");

    return (
      <React.Fragment>
        <nav
          className={
            this.props.redNav
              ? "navbar fixed-top navbar-expand-lg navbar-dark redNav"
              : "navbar fixed-top navbar-expand-lg navbar-dark"
          }
          data-expanded={this.state.expanded ? "isExpanded" : ""}
        >
          <div className="container-fluid">
            <div
              onClick={() => this.props.onLangChange()}
              className="navbar-brand"
            >
              {this.props.lang === "pt-br" ? (
                <img src={EN} alt="" />
              ) : (
                <img src={PT} alt="" />
              )}
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNav"
              aria-controls="navbarNav"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={this._handleExpand}
            >
              <span className="navbar-toggler-icon" />
            </button>
            <div
              className="collapse navbar-collapse justify-content-end"
              id="navbarNav"
            >
              <ul className="navbar-nav">
                {items && items.map(menuitem => {
                  return (
                    <li className="nav-item" key={menuitem.ID}>
                      <AnchorLink className="nav-link" href={menuitem.url}>
                        {menuitem.title}
                      </AnchorLink>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default Navbar;
