import React, { Component } from "react";
import axios from "axios";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "./style.css";
import Navbar from "./components/navbar";
import Hero from "./components/hero";
import Trailer from "./components/trailer";
import Sinopse from "./components/sinopse";
import PressCoverage from "./components/presscoverage";
import Stills from "./components/stills";
import StillOverlay from "./components/stillOverlay";
import Reviews from "./components/reviews";
import Agenda from "./components/agenda";
import PressBook from "./components/pressbook";
import Equipe from "./components/equipe";
import { Waypoint } from "react-waypoint";
import Contato from "./components/contato";
import LoadingScreen from "./components/loadingScreen";
import FormularioExibicao from "./components/formularioExibicao";
import Assistir from "./components/assistir";

const api = "https://democraciaemvertigem.com/?rest_route=/wp/v2";

class App extends Component {
  pages = {
    hero: { "pt-br": "44", en: "47" },
    trailer: { "pt-br": "15", en: "40" },
    sinopse: { "pt-br": "17", en: "38" },
    stills: { "pt-br": "84", en: "84" },
    equipe: { "pt-br": "56", en: "58" },
    contato: { "pt-br": "189", en: "191" },
    reviews: { "pt-br": "146", en: "166" },
    agenda: { "pt-br": "130", en: "187" },
    exibicoesForm: { "pt-br": "777", en: "" }
  };
  state = {
    loading: true,
    loadingMessage: "Carregando...",
    menus: [],
    redNav: true,
    lang: "pt-br",
    pressCoveragePages: 0,
    currentPressPage: 1,
    trailerPage: { content: {}, title: {}, acf: {} },
    heroPage: {
      content: {},
      title: {},
      _embedded: {
        "wp:featuredmedia": [
          {
            media_details: { sizes: { full: {} } }
          }
        ]
      }
    },
    sinopsePage: {
      content: {},
      title: {},
      acf: {},
      _embedded: {
        "wp:featuredmedia": [
          {
            media_details: { sizes: { full: {} } }
          }
        ]
      }
    },
    equipePage: { content: {}, title: {} },
    agendaPage: { content: {}, title: {} },
    contatoPage: { content: {}, title: {} },
    exibicoesPage: { content: {}, title: {} },
    stillsPage: { content: {}, title: {}, acf: { galeria: [] } },
    reviewsPage: {
      content: {},
      title: {},
      acf: { review1: {}, review2: {}, review3: {}, review4: {} }
    },
    openStill: false,
    selectedStill: "",
    pressCoverage: [{ content: { rendered: "" }, title: {}, date: "", acf: {} }]
  };

  getContent = lang => {
    let loadingMessage = lang === "pt-br" ? "Carregando..." : "Loading...";

    this.setState({ loadingMessage, loading: true }, async () => {
      try {
        const getMenu = axios.get(api + "/" + lang);
        const getHero = axios.get(
          api + "/pages/" + this.pages.hero[lang] + "&_embed"
        );
        const getPressCoverage = axios.get(
          api +
            "/mediacoverage&per_page=6&orderby=menu_order&order=asc" +
            (lang === "en" ? "&lang=" + lang : "")
        );
        const getTrailer = axios.get(
          api + "/pages/" + this.pages.trailer[lang]
        );
        const getSinopse = axios.get(
          api + "/pages/" + this.pages.sinopse[lang] + "&_embed"
        );
        const getEquipe = axios.get(api + "/pages/" + this.pages.equipe[lang]);
        const getScreenings = axios.get(
          api + "/pages/" + this.pages.agenda[lang]
        );
        const getContato = axios.get(
          api + "/pages/" + this.pages.contato[lang]
        );
        const getReviews = axios.get(
          api + "/pages/" + this.pages.reviews[lang]
        );
        const getExibicoesForm = axios.get(api + "/pages/777");
        const getStills = axios.get(api + "/pages/84");

        let [
          menus,
          heroPage,
          pressCoverage,
          trailerPage,
          sinopsePage,
          equipePage,
          stillsPage,
          reviewsPage,
          agendaPage,
          contatoPage,
          exibicoesPage,
        ] = await Promise.allSettled([
          getMenu,
          getHero,
          getPressCoverage,
          getTrailer,
          getSinopse,
          getEquipe,
          getStills,
          getReviews,
          getScreenings,
          getContato,
          getExibicoesForm
        ]);

        const pressCoveragePages = parseInt(
          pressCoverage.status !== "rejected" && pressCoverage.headers["x-wp-totalpages"]
        ) || 0;

        menus = menus && menus.status !== "rejected" && menus.value.data;
        heroPage = heroPage && heroPage.status !== "rejected" && heroPage.value.data;
        pressCoverage = pressCoverage && pressCoverage.status !== "rejected" && pressCoverage.value.data;
        trailerPage = trailerPage && trailerPage.status !== "rejected" && trailerPage.value.data;
        sinopsePage = sinopsePage && sinopsePage.status !== "rejected" && sinopsePage.value.data;
        equipePage = equipePage && equipePage.status !== "rejected" && equipePage.value.data;
        stillsPage = stillsPage && stillsPage.status !== "rejected" && stillsPage.value.data;
        reviewsPage = reviewsPage && reviewsPage.status !== "rejected" && reviewsPage.value.data;
        agendaPage = agendaPage && agendaPage.status !== "rejected" && agendaPage.value.data;
        contatoPage = contatoPage && contatoPage.status !== "rejected" && contatoPage.value.data;
        exibicoesPage = exibicoesPage && exibicoesPage.status !== "rejected" && exibicoesPage.value.data;

        this.setState({
          menus,
          heroPage,
          pressCoverage,
          pressCoveragePages,
          trailerPage,
          sinopsePage,
          equipePage,
          stillsPage,
          reviewsPage,
          agendaPage,
          contatoPage,
          exibicoesPage,
          loading: false
        });
        const video = document.getElementById("videoBg");
        video && video.play();
      } catch (error) {
        console.log(error);
        this.setState({
          loadingMessage: "Erro no carregamento: " + error.message
        });
      }
    });
  };
  handleLangChange = () => {
    let lang = "";
    this.state.lang === "pt-br" ? (lang = "en") : (lang = "pt-br");
    this.props.history.push(`/?lang=${lang}`);
    const currentPressPage = 1;
    this.setState({ lang, currentPressPage }, () => this.getContent(lang));
  };
  handleOpenStill = stillId => {
    const imagens = [...this.state.stillsPage.acf.galeria];
    const imagem = imagens.filter(imagemObj => {
      return imagemObj.ID === stillId;
    })[0];
    const selectedStill = imagem;

    if (window.innerWidth > 720) {
      const openStill = true;
      this.setState({ openStill, selectedStill });
    } else {
      window.open(imagem.url, "_blank");
    }
  };

  handleCloseStill = () => {
    this.setState({ openStill: false, selectedStill: "" });
  };
  _handleHeroEnter = () => {
    const redNav = !this.state.redNav;
    this.setState({ redNav });
  };
  _handleLoadMore = async () => {
    const currentPressPage = this.state.currentPressPage + 1;

    const getPressCoverage = await axios.get(
      api +
        "/mediacoverage&orderby=menu_order&order=asc&per_page=6&page=" +
        currentPressPage +
        (this.state.lang === "en" ? `&lang=${this.state.lang}` : "")
    );
    const pressCoverage = this.state.pressCoverage.concat(
      getPressCoverage.data
    );
    this.setState({ pressCoverage, currentPressPage });
  };
  componentDidMount() {
    const query = this.props.location.search;
    const params = new URLSearchParams(query);
    const lang = params.get("lang");
    if (lang === "pt-br" || lang === "en") {
      this.setState({ lang }, () => {
        this.getContent(this.state.lang);
      });
    } else {
      this.getContent(this.state.lang);
    }
  }
  render() {
    const { lang } = this.state;
    return (
      <div id="App" className="App">
        {/* {this.state.loading ? <LoadingScreen /> : ""} */}
        <Navbar
          items={this.state.menus}
          lang={this.state.lang}
          onLangChange={this.handleLangChange}
          redNav={this.state.redNav}
        />
        <Hero heroPage={this.state.heroPage} />
        <Assistir lang={this.state.lang} />
        {this.state.loading ? (
          ""
        ) : (
          <div className="section__reviews">
            <Reviews reviewsPage={this.state.reviewsPage} />
            <Trailer trailerPage={this.state.trailerPage} />
          </div>
        )}

        {this.state.loading ? (
          ""
        ) : (
          <Sinopse sinopsePage={this.state.sinopsePage} />
        )}

        <PressCoverage
          lang={this.state.lang}
          pressCoverage={this.state.pressCoverage}
          onLoadMore={this._handleLoadMore}
          currentPressPage={this.state.currentPressPage}
          pressCoveragePages={this.state.pressCoveragePages}
        />
        {/* <PressBook /> */}
        <Agenda agendaPage={this.state.agendaPage} />
        {/* {lang === "pt-br" ? (
          <FormularioExibicao
            lang={this.state.lang}
            exibicoesPage={this.state.exibicoesPage}
          />
        ) : (
          ""
        )} */}
        <Stills
          stillsPage={this.state.stillsPage}
          onOpenStill={this.handleOpenStill}
        />
        <StillOverlay
          onCloseStill={this.handleCloseStill}
          onOpenStill={this.handleOpenStill}
          selectedStill={this.state.selectedStill && this.state.selectedStill}
          openStill={this.state.openStill && this.state.openStill}
          images={this.state.stillsPage && this.state.stillsPage.acf.galeria}
          stillsPage={this.state.stillsPage && this.state.stillsPage}
        />
        {this.state.loading ? (
          ""
        ) : (
          <Equipe equipePage={this.state.equipePage} lang={this.state.lang} />
        )}
        {/* {this.state.loading ? (
          ""
        ) : (
           <Contato contatoPage={this.state.contatoPage} />
        )} */}
        <div className="creditos">
          © 2019 THE EDGE OF DEMOCRACY. All Rights Reserved. | Developed by{" "}
          <a
            href="https://viniciusofp.com.br"
            target="_blank"
            rel="noopener noreferrer"
          >
            viniciusofp
          </a>
        </div>
      </div>
    );
  }
}

export default App;
