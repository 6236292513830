import React, { Component } from "react";
import { Waypoint } from "react-waypoint";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";

library.add(fab, fas);

class PressCoverage extends Component {
  state = {
    boxClass: "press_box col-md-6 col-lg-4",
    post: { title: { rendered: "" }, content: { rendered: "" } }
  };
  _handlePressEnter = () => {
    this.setState({ boxClass: "press_box col-md-6 col-lg-4 enter" });
  };

  render() {
    if(!this.props.pressCoverage){
      return null
    }
    return (
      <div id="imprensa" className="section__pressCoverage">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="section__title red">
                {this.props.lang === "pt-br" ? "Imprensa" : "Press Coverage"}
              </h2>
            </div>
            {this.props.pressCoverage.map(post => {
              var options = {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric"
              };
              const date = new Date(post.date);
              const externalPost = (
                <div
                  className="press_box col-md-6 col-lg-4 enter"
                  key={post.id + "press"}
                >
                  <a
                    href={post.acf.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="veiculo">{post.acf.veiculo}</span>
                    <h6>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: post.title.rendered
                        }}
                      />
                      <small>
                        <FontAwesomeIcon icon={["fas", "external-link-alt"]} />
                      </small>
                    </h6>
                  </a>
                  {/* <p
                    className="date"
                    dangerouslySetInnerHTML={{
                      __html: date.toLocaleDateString(this.props.lang, options)
                    }}
                  /> */}
                </div>
              );
              const internalPost = (
                <React.Fragment>
                  <div
                    className="press_box col-md-6 col-lg-4 enter"
                    key={post.id + "press"}
                  >
                    <Link
                      to={`/mediacoverage/${post.slug}/${post.id}/?lang=${
                        this.props.lang
                      }`}
                    >
                      <span className="veiculo">{post.acf.veiculo}</span>
                      <h6
                        dangerouslySetInnerHTML={{
                          __html: post.title.rendered
                        }}
                      />
                    </Link>
                    {/* <p
                      className="date"
                      dangerouslySetInnerHTML={{
                        __html: date.toLocaleDateString(
                          this.props.lang,
                          options
                        )
                      }}
                    /> */}
                  </div>
                </React.Fragment>
              );
              return post.content.rendered.length > 0
                ? internalPost
                : externalPost;
            })}
            {this.props.currentPressPage < this.props.pressCoveragePages ? (
              <div className="col-12 text-center">
                <button
                  className="btn btn-secondary"
                  onClick={() => this.props.onLoadMore()}
                >
                  {this.props.lang === "pt-br"
                    ? "Carregar mais notícias"
                    : "Load more news"}
                </button>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PressCoverage;
