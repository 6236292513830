import React, { Component } from "react";
import { Waypoint } from "react-waypoint";

class Trailer extends Component {
  state = { trailerClass: "section__trailer", onScreen: true };
  _handleTrailerEnter = () => {
    this.setState({ trailerClass: "section__trailer enter", onScreen: true });
  };
  render() {
    if(!this.props.trailerPage){
      return null;
    }
    console.log(this.props.trailerPage.acf.exibir_trailer);
    const trailer = (
      <div id="trailer" className="section__trailer enter">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-12">
              {this.state.onScreen ? (
                <div
                  className="my-5"
                  dangerouslySetInnerHTML={{
                    __html: this.props.trailerPage.content.rendered
                  }}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
    return (
      <div>{this.props.trailerPage.acf.exibir_trailer ? trailer : ""}</div>
    );
  }
}

export default Trailer;
