import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

class Reviews extends Component {
  state = {
    boxClass: "col-md-6 col-xl-3 align-self-center review_box enter"
  };
  _handleReviewsEnter = () => {
    const boxClass = this.state.boxClass + " enter";
    this.setState({ boxClass });
  };
  componentDidMount() {
    const logos = document.getElementsByClassName("reviewLogo");
    const logosArray = [...logos];
    console.log(logosArray);
    let maxHeight = 0;
    setTimeout(() => {
      logosArray.forEach(logo => {
        const height = logo.clientHeight;
        if (height > maxHeight) {
          maxHeight = height;
        }
      });

      logosArray.forEach(logo => {
        const height = logo.clientHeight;
        const margin = (maxHeight - height) / 2;
        const marginBottom = margin + 15;
        logo.style.margin = margin + "px auto " + marginBottom + "px";
      });
    }, 1000);
  }
  render() {
    if(!this.props.stillsPage){
      return null
    }
    const reviews = Object.values(this.props.reviewsPage.acf);
    const settings = {
      dots: true,
      infinite: true,
      pauseOnFocus: true,
      speed: 500,
      slidesToShow: 3,
      slidesToScroll: 1,
      autoplay: true,
      responsive: [
        {
          breakpoint: 991,
          settings: {
            dots: true,
            infinite: false,
            pauseOnFocus: true,
            speed: 500,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true
          }
        },
        {
          breakpoint: 767,
          settings: {
            dots: true,
            infinite: false,
            pauseOnFocus: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
          }
        }
      ]
    };
    return (
      <div className="container">
        <div className="row text-center justify-content-center mb-3">
          <div className="col-12">
            <Slider {...settings}>
              {reviews.map((review, i) => {
                return (
                  <div className="review_box enter" key={`review${i}`}>
                    <img className="reviewLogo" src={review.logo} alt="" />
                    <a
                      href={review.link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <h2 className="review_title">{review.texto}</h2>
                    </a>
                    {/* <h5>{review.autor}</h5> */}
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    );
  }
}

export default Reviews;
