import React, { Component } from "react";

class FormularioExibicao extends Component {
  state = {};
  render() {
    if(!this.props.exibicoesPage){
      return null
    }
    return (
      <div id="exibicao" className="section__formularioExibicao">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2
                className="section__title"
                dangerouslySetInnerHTML={{
                  __html: this.props.exibicoesPage.title.rendered
                }}
              />
            </div>
            <div
              className="col-12 section__formularioExibicao_content"
              dangerouslySetInnerHTML={{
                __html: this.props.exibicoesPage.content.rendered
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default FormularioExibicao;
